import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import AddLocationIcon from "@mui/icons-material/AddLocation";
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DialogAddRouteAutoComplete from '../AddRouteComponent/DialogAddRouteAutoComplete';
import './RouteTable.css';
import {
  useDeleteRouteStopMutation,
  useDisableRouteStopMutation,
  useEnableRouteStopMutation,
} from '../../../features/routestops/routeStopSlice';
import './RouteTable.css';
import {
  useDisableRouteMutation,
  useEnableRouteMutation,
} from '../../../features/routes/routesSlice';

import ComfirmationDialog from '../../Dialog/ConfirmationDialog';
import { useSnackbar } from '../../SneckBarProvider';
import { AddRouteStop } from '../../RouteStops/AddRouteStop/AddRouteStopComponent/AddRouteStop';
// import MapIcon from "@mui/icons-material/Map";
import DisplayRouteDialog from '../DisplayRouteComponent/DisplayRouteDialog';
import CheckIcon from '@mui/icons-material/Check';
// import ClearIcon from "@mui/icons-material/Clear";
import { CheckUserAuth } from '../../../utils/checkAuth/checkUserAuth';
import { AdminCheck } from '../../../utils/checkAuth/checkUserAuth';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { ReactComponent as MapIcon } from '../../../Icons/map-01.svg';
import { ReactComponent as AddLocationIcon } from '../../../Icons/marker-pin-01.svg';
import { ReactComponent as EditIcon } from '../../../Icons/edit-01.svg';
import { ReactComponent as ClearIcon } from '../../../Icons/x-close.svg';
import { translateUnammedNumber } from '../../../utils/translateUnammedLocation';
import { translateUnammedStreet } from '../../../utils/translateUnammedLocation';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import RestrictDatesDialog from '../RestrictDates/RestrictDatesDialog';

function Row(props) {
  const matches = useMediaQuery('(max-height: 900px)');

  const { route } = props;
  const { t, i18n } = useTranslation();
  const [enabledRouteStops, setEnabledRouteStops] = React.useState(
    route.routeStops.filter((stop) => stop.isEnabled).slice(2)
  );
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(0);
  const [sortedStops, setSorted] = React.useState(null);
  const [confDialogMessage, setMessage] = React.useState(null);

  const [dialog, setDialog] = React.useState('');
  const [enableRouteStop] = useEnableRouteStopMutation();
  const [disableRouteStop] = useDisableRouteStopMutation();
  const [disableRoute] = useDisableRouteMutation();
  const [enableRoute] = useEnableRouteMutation();
  const [deleteRouteStop] = useDeleteRouteStopMutation();

  const showSnackbar = useSnackbar();
  const messages = [
    'disable route',
    'enable route',
    'disable stop',
    'enable stop',
    'delete stop',
  ];

  // const role = localStorage.getItem("roles");
  React.useEffect(() => {
    setEnabledRouteStops(
      route.routeStops.filter((stop) => stop.isEnabled).slice(2)
    );
  }, [route]);

  const onEdit = () => {
    setDialog('update');
    setOpenDialog(true);
  };

  const openConfDialog = (id, message) => {
    console.log(id);
    setSelectedId(id);
    setMessage(message);
    setDialog('confirmation');
    setOpenDialog(true);
  };

  const handleEnableRoute = async () => {
    try {
      const response = await enableRoute(selectedId).unwrap();
      showSnackbar(response?.message, 'success');
      props.heandleUpdate(selectedId);
      setOpenDialog(false);
    } catch (err) {
      showSnackbar(err?.data?.Message, 'error');
    }
  };

  const handleDisableRoute = async () => {
    try {
      const response = await disableRoute(selectedId).unwrap();
      showSnackbar(response?.message, 'success');
      props.heandleUpdate(selectedId);
      setOpenDialog(false);
    } catch (err) {
      showSnackbar(err?.data?.Message, 'error');
    }
  };

  const handleEnableRouteStop = async () => {
    try {
      const response = await enableRouteStop(selectedId).unwrap();
      heandleUpdate();
      showSnackbar(response?.message, 'success');
      setOpenDialog(false);
    } catch (err) {
      showSnackbar(err?.data?.Message, 'error');
    }
  };
  const handleDisableRouteStop = async () => {
    try {
      const response = await disableRouteStop(selectedId).unwrap();
      heandleUpdate();
      showSnackbar(response?.message, 'success');
      setOpenDialog(false);
    } catch (err) {
      showSnackbar(err?.data?.Message, 'error');
    }
  };

  const handleDeleteRouteStop = async () => {
    try {
      const response = await deleteRouteStop(selectedId).unwrap();
      heandleUpdate();
      showSnackbar(response?.message, 'success');
      setOpenDialog(false);
    } catch (err) {
      showSnackbar(err?.data?.Message, 'error');
    }
  };

  const handleYesClick = () => {
    if (confDialogMessage === messages[0]) {
      handleDisableRoute();
    } else if (confDialogMessage === messages[1]) {
      handleEnableRoute();
    } else if (confDialogMessage === messages[2]) {
      handleDisableRouteStop();
    } else if (confDialogMessage === messages[3]) {
      handleEnableRouteStop();
    } else if (confDialogMessage === messages[4]) {
      handleDeleteRouteStop();
    }
  };
  const heandleUpdate = () => {
    console.log('fasddasfafffdas');
    props.heandleUpdate();
    if (dialog == 'update') setOpenDialog(false);
  };
  const handleAddedRouteStop = (id) => {
    props.heandleUpdate(id);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenAddRouteStopDialog = () => {
    setDialog('addRouteStop');
    setOpenDialog(true);
  };
  const openDisplayMapDialog = () => {
    setDialog('display');
    setOpenDialog(true);
  };
  const openRestrictDatesDialog = () => {
    setDialog('restrictDates');
    setOpenDialog(true);
  };
  function getLocationDisplay(location) {
    if (location.title === '') {
      if (location.city === 'Undefined city') {
        return location.country;
      }
      var street = '';
      if (location.street !== 'Unnamed Road') {
        street = location.street;
      }
      const number = translateUnammedNumber(location.number, t);
      if (street === '' && number == '') {
        return location.city;
      }

      return `${location.city} ( ${street} ${number} )`;
    }
    return location.title;
  }
  // onOpensetOpen(!open)}

  // console.log(route)

  React.useEffect(() => {
    if (open) {
      console.log('fdasfsdfas');
      let locationsWithDistance = route.routeStops
        .map((rs, index) => {
          const distance = Math.sqrt(
            Math.pow(rs.location.lat - route.startLocation.lat, 2) +
              Math.pow(rs.location.long - route.startLocation.long, 2)
          );
          return { rs, distance };
        })
        .sort((a, b) => a.distance - b.distance);
      console.log('sortet', locationsWithDistance);
      locationsWithDistance.sort((a, b) => a.distance - b.distance);
      setSorted(locationsWithDistance);
    }
  }, [open, route]);

  // console.log(locationsWithDistance)

  return (
    <React.Fragment>
      <TableRow sx={{ borderTop: '1px solid rgba(224, 224, 224, 1);' }}>
        <TableCell size={matches ? 'small' : 'medium'}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{route.title}</TableCell>
        <TableCell sx={{ textWrap: 'nowrap' }}>{route.price} €</TableCell>
        <TableCell sx={{ textWrap: 'nowrap' }}>
          {route.price * 120} RSD
        </TableCell>

        <TableCell>
          {/* {route.startLocation.city} */}

          {getLocationDisplay(route.startLocation)}
        </TableCell>
        <TableCell>
          {/* {route.endLocation.city} */}
          {getLocationDisplay(route.endLocation)}
        </TableCell>
        <TableCell align="center">
          {route.isEnabled === true ? (
            <div className="route-table-status-true">
              <CheckIcon style={{ fontSize: '0.875rem', color: '#12B76A' }} />
              {t('route-list.active')}
            </div>
          ) : (
            <div className="route-table-status-false">
              <ClearIcon style={{ fontSize: '0.875rem', color: '#F04438' }} />
              {t('route-list.inactive')}
            </div>
          )}
        </TableCell>
        {CheckUserAuth() && (
          <TableCell sx={{ textWrap: 'nowrap' }} align="center">
            <Tooltip title={t('route-tooltips.displayRoute')}>
              <MapIcon
                className="pointer svg-class-change"
                onClick={openDisplayMapDialog}
              ></MapIcon>
            </Tooltip>
            <Tooltip title={t('route-tooltips.addStop')}>
              <AddLocationIcon
                onClick={handleOpenAddRouteStopDialog}
                className="pointer svg-class-change"
              ></AddLocationIcon>
            </Tooltip>
            <>
              <Tooltip title={t('route-tooltips.editRoute')}>
                <EditIcon
                  onClick={onEdit}
                  className="pointer svg-class-change"
                />
              </Tooltip>
              {route.isEnabled ? (
                <Tooltip title={t('route-tooltips.disableRoute')}>
                  <ClearIcon
                    className="pointer svg-class-change"
                    onClick={() => openConfDialog(route.id, messages[0])}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={t('route-tooltips.enableRoute')}>
                  <CheckIcon
                    className="pointer svg-class-change"
                    onClick={() => openConfDialog(route.id, messages[1])}
                  />
                </Tooltip>
              )}
              {/* <Tooltip title={t('route-tooltips.editRoute')}>
                 <EditCalendarIcon
                  onClick={openRestrictDatesDialog}
                /> 
              </Tooltip> */}
            </>
          </TableCell>
        )}
      </TableRow>
      {sortedStops && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {t('route-list.locationTitle')}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b> {t('route-list.City')}</b>
                      </TableCell>
                      <TableCell>
                        <b> {t('route-list.Country')}</b>
                      </TableCell>
                      <TableCell>
                        <b>{t('route-list.Street')}</b>
                      </TableCell>
                      <TableCell>
                        <b>{t('route-list.Number')}</b>
                      </TableCell>
                      {CheckUserAuth() && (
                        <TableCell align="center">
                          <b>{t('route-list.Enable/Disable Location')}</b>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedStops.map((routeStop) => (
                      // console.log(routeStop.rs),
                      <TableRow
                        key={routeStop.rs.id}
                        style={
                          // (!routeStop.isEnabled)
                          !routeStop.rs.isEnabled
                            ? {
                                backgroundColor: 'rgb(255, 226, 226)',
                                borderBottom: '2px solid rgb(255, 123, 123)',
                              }
                            : {}
                        }
                      >
                        <TableCell>
                          {' '}
                          {routeStop.rs.location.city === 'Undefined city'
                            ? t('Location.undefinedCity')
                            : routeStop.rs.location.city}
                        </TableCell>
                        <TableCell>{routeStop.rs.location.country}</TableCell>
                        <TableCell>
                          {routeStop.rs.location.street === 'Unnamed Road'
                            ? t('Location.unnamedRoad')
                            : routeStop.rs.location.street}
                        </TableCell>
                        <TableCell>
                          {routeStop.rs.location.number === 'NN'
                            ? t('Location.NN')
                            : routeStop.rs.location.number}
                        </TableCell>
                        {CheckUserAuth() && (
                          <TableCell align="center">
                            {(routeStop.rs.isEnabled && (
                              <Tooltip
                                title={t('route-list.Disable Route Stop')}
                              >
                                <>
                                  {route.startStopId !== routeStop.rs.id &&
                                    route.endStopId != routeStop.rs.id && (
                                      <LocationOffIcon
                                        onClick={() =>
                                          openConfDialog(
                                            routeStop.rs.id,
                                            messages[2]
                                          )
                                        }
                                        className="pointer"
                                      />
                                    )}
                                </>
                              </Tooltip>
                            )) || (
                              <Tooltip
                                title={t('route-list.Enable Route Stop')}
                              >
                                <LocationOnIcon
                                  onClick={() =>
                                    openConfDialog(routeStop.rs.id, messages[3])
                                  }
                                  className="pointer"
                                />
                              </Tooltip>
                            )}
                            {AdminCheck() &&
                              route.startStopId !== routeStop.rs.id &&
                              route.endStopId != routeStop.rs.id && (
                                <Tooltip
                                  title={t('route-list.Delete Route Stop')}
                                >
                                  <DeleteOutlineIcon
                                    className="pointer"
                                    onClick={() =>
                                      openConfDialog(
                                        routeStop.rs.id,
                                        messages[4]
                                      )
                                    }
                                  />
                                </Tooltip>
                              )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      {route && dialog === 'update' && (
        <>
          <DialogAddRouteAutoComplete
            route={route}
            onAddNewRoute={heandleUpdate}
            onClose={handleCloseDialog}
            open={openDialog}
          />
        </>
      )}
      {route && dialog === 'addRouteStop' && (
        <AddRouteStop
          open={openDialog}
          onClose={handleCloseDialog}
          onAddRouteStop={handleAddedRouteStop}
          origin={route.startLocation}
          destination={route.endLocation}
          routeId={route.id}
          routeStops={enabledRouteStops}
          routeTitle={route.title}
        />
      )}
      {route && dialog === 'confirmation' && confDialogMessage && (
        <ComfirmationDialog
          open={openDialog}
          onClose={handleCloseDialog}
          dialogContent={`Do you want to ${confDialogMessage}?`}
          onNo={handleCloseDialog}
          onYes={handleYesClick}
        />
      )}
      {route && dialog === 'display' && (
        <DisplayRouteDialog
          open={openDialog}
          onClose={handleCloseDialog}
          origin={route.startLocation}
          destination={route.endLocation}
          routeStops={enabledRouteStops}
        />
      )}
      {/* {route && dialog === 'restrictDates' && (

      <RestrictDatesDialog
          open={openDialog}
          onClose={handleCloseDialog}

          />
      )} */}
    </React.Fragment>
  );
}
export default function RouteTable(props) {
  const { routes, heandleEdit } = props;
  const { t, i18n } = useTranslation();
  const role = localStorage.getItem('roles');
  console.log('rola', role);
  const matches = useMediaQuery('(max-height: 900px)');

  // const hendleUpdatedRoute = (data) => {
  //   props.heandleEdit();
  //   console.log("tabelaaa", data);
  // };

  return (
    <div className="route-table-main-container">
      {routes && (
        <TableContainer component={Paper}>
          <Table
            size={matches ? 'small' : 'medium'}
            aria-label="collapsible table"
          >
            <TableHead>
              <TableRow style={{ backgroundColor: '#F9FAFB' }}>
                <TableCell />
                <TableCell align="center">
                  <b>{t('route-list.title')}</b>
                </TableCell>
                <TableCell sx={{ textWrap: 'nowrap' }} align="center">
                  <b>{t('route-list.price')} (€)</b>
                </TableCell>
                <TableCell sx={{ textWrap: 'nowrap' }} align="center">
                  <b>{t('route-list.price')} (RSD)</b>
                </TableCell>
                <TableCell align="center">
                  <b>{t('route-list.startLocation')}</b>
                </TableCell>
                <TableCell align="center">
                  <b>{t('route-list.endLocation')}</b>
                </TableCell>
                <TableCell sx={{ textAlign: 'left !important' }}>
                  <b>Status</b>
                </TableCell>
                {CheckUserAuth() && (
                  <TableCell
                    className="left-table-cell-route-table"
                    sx={{ textAlign: 'left !important' }}
                  >
                    <b>{t('route-list.actions')}</b>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {routes.map((route) => (
                <Row heandleUpdate={heandleEdit} key={route.id} route={route} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

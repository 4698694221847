import * as React from 'react';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const initialValue = dayjs('2022-04-17');

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? '🔒' : undefined}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

export default function RestricDates() {
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15,30]);
  const [selectedDate, setSelectedDate] = React.useState(dayjs());

//   const handleMonthChange = (date) => {
//     if (requestAbortController.current) {
//       // make sure that you are aborting useless requests
//       // because it is possible to switch between months pretty quickly
//       requestAbortController.current.abort();
//     }

//     setIsLoading(true);
//     setHighlightedDays([]);
//     // fetchHighlightedDays(date);
//   };

const handleDateChange = (newDate) => {
    setSelectedDate(newDate); // Update state with the new date
    console.log('Selected Date:', newDate.format('YYYY-MM-DD')); // Log the new date

    const day = newDate.date();
    setHighlightedDays((prevHighlightedDays) => {
        // Check if the day is already highlighted
        if (prevHighlightedDays.includes(day)) {
          // Remove the day if it's already highlighted
          return prevHighlightedDays.filter((d) => d !== day);
        } else {
          // Add the day if it's not highlighted
          return [...prevHighlightedDays, day];
        }
      });

  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        disablePast
        value={selectedDate}
        defaultValue={selectedDate}
        loading={isLoading}
        onChange={handleDateChange}
        // onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
        }}
      />
    </LocalizationProvider>
  );
}